import AppItemCard from "components/app-item-card";
import { getLang } from "config/regionlang";
import React from "react";
import { withTranslation } from "react-i18next";
import "./index.css";

const GroupContent = (props) => {
  console.log(props);
  const { appList, lang, groupName } = props;
  return <div>
    {/* <div className="group_title">{groupName}{getLang(lang)["necessary"]}</div> */}
    <div>
      {
        appList && appList.map((item, index) => {
          return <AppItemCard key={index} data={item} lang={lang} />
        })
      }
    </div>

  </div>
}

export default withTranslation()(GroupContent);