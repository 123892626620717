import AppDetail from "views/app-detail"
import ChairmanReaderDownload from "views/chairman-reader-download"
import AppList from "views/app-list"
import Home from "views/home"
import NewUser from "views/new-user"
import SearchList from "views/search-list"
import VersionList from "views/version-list"
import SharePage from "views/share-page"

const routerConfig = [
  {
    path: "/home",
    component: Home,
    children: [
      {
        path: "/home/app-list",
        exact: true,
        component: AppList
      },
      {
        path: "/home/new-user",
        exact: true,
        component: NewUser
      }
    ]
  },
  {
    path: "/app-detail/:appId",
    exact: true,
    component: AppDetail
  },
  {
    path: "/version-list/:appId",
    exact: true,
    component: VersionList
  },
  {
    path: "/search-list",
    exact: true,
    component: SearchList
  },
  {
    path: "/chairman-reader-download",
    exact: true,
    component: ChairmanReaderDownload
  },
  {
    path: "/share-page/:appId",
    exact: true,
    component: SharePage
  }
]

export default routerConfig