import { Image } from "antd-mobile";
import { ArrowDownCircleOutline } from "antd-mobile-icons";
import DownloadButton from "components/download-button";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { numberFormat } from "utils/utils";
import "./index.css"

const AppItemCard = (props) => {
  const history = useHistory();
  const { data, lang } = props;
  const { appInfo, platforms } = data;
  const [downloadCount, setDownloadCount] = useState(appInfo.download_counts);
  const goDetail = () => {
    history.push(`/app-detail/${appInfo.app_id}?lang=${lang}`);
  }

  const downloadAdd = () => {
    setDownloadCount(downloadCount + 1);
  }

  return <div className="app-item-card">
    <div className="app-item-card-wrap" onClick={goDetail}>
      <div className="app-item-card-icon">
        <Image
          src={appInfo.app_icon}
          fit='cover'
        />
      </div>
      <div className="app-item-card-right">
        <div className="app-item-card-content">
          <div className="app-item-card-name">{appInfo.app_name}</div>
          <div className="app-item-card-download-num"><ArrowDownCircleOutline />{numberFormat(downloadCount)}</div>
        </div>
        <DownloadButton platforms={platforms} appId={appInfo.app_id} downloadAdd={downloadAdd} lang={lang} />
      </div>
    </div>
    <div className="app-item-card-desc">{appInfo.simple_desc}</div>
  </div>
}
export default AppItemCard;