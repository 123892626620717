import { Image } from "antd-mobile";
import { ArrowDownCircleOutline } from "antd-mobile-icons";
import DownloadButton from "components/download-button";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { numberFormat } from "utils/utils";
import "./index.css"

const AppItem = (props) => {
  const history = useHistory();
  const { appInfo, platforms } = props.data;
  const [downloadCount, setDownloadCount] = useState(appInfo.download_counts);
  const goDetail = () => {
    history.push(`/app-detail/${appInfo.app_id}`);
  }

  const downloadAdd = () => {
    setDownloadCount(downloadCount + 1);
  }

  return <div className="app-item-wrap" onClick={goDetail}>
    <div className="app-item-icon">
      <Image
        src={appInfo.app_icon}
        fit='cover'
      />
    </div>
    <div className="app-item-right">
      <div className="app-item-content">
        <div className="app-item-name">{appInfo.app_name}</div>
        <div className="app-item-download-num"><ArrowDownCircleOutline />{numberFormat(downloadCount)}</div>
      </div>
      <DownloadButton platforms={platforms} appId={appInfo.app_id} downloadAdd={downloadAdd} />
    </div>
  </div>
}
export default AppItem;