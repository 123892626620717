import { Ellipsis, Grid, Image } from "antd-mobile";
import api from "api";
import ChairmanDownloadButton from "components/chairman-download-button";
import React, { useEffect } from "react";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import "./index.css";

const ChairmanReaderDownload = () => {
  const chairmanGroupID = process.env.REACT_APP_CHAIRMAN_GROUP_ID;
  const [chairmanReaderList, setChairmanReaderList] = useState([]);

  useEffect(() => {
    getChairmanReaderList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChairmanReaderList = async () => {
    const res = await api.getGroupAppById(chairmanGroupID);
    if (res.code === 200) {
      setChairmanReaderList(res.data?.applist || []);
    }
  }

  return <div>
    <Grid columns={6} className="chairman-reader-wrap">
      {
        chairmanReaderList && chairmanReaderList.map((item, index) => {
          return <Grid.Item>
            <div className="chairman-reader-item" key={index}>
              <div className="reader-item-icon-wrap">
                <Image
                  src={item.appInfo.app_icon}
                  fit='cover'
                  className="reader-item-icon"
                />
              </div>
              <Ellipsis direction='middle' content={item?.appInfo.app_name} className="reader-item-name" />
              <ChairmanDownloadButton platforms={item?.platforms} appId={item.appInfo.app_id} />
            </div>
          </Grid.Item>
        })
      }
    </Grid>
  </div>
}

export default withTranslation()(ChairmanReaderDownload);