import api from "api";
import QRCode from "qrcode-react";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useLocation, useParams, withRouter } from "react-router-dom";
import { calcTime, getOS, getUrlkey, mergeImage, numberFormat } from "utils/utils";
import { Dialog, Ellipsis, Image, Popup, Toast } from "antd-mobile";
import { toPng } from "html-to-image";
import QRCode1 from 'qrcode'
import "./index.css";
import { ArrowDownCircleOutline, CloseOutline } from "antd-mobile-icons";
import DownloadButton from "components/download-button";
import ShareIcon from "assets/share-icon.svg";
import ShareCopyLinkIcon from "assets/share-copy-link-icon.svg";
import ShareCreateQrcodeIcon from "assets/share-create-qrcode-icon.svg";
import SanyLogoRed from "assets/sany-logo-red.svg";
import { getLang } from "config/regionlang";
import { useTranslation } from "react-i18next";
import Clipboard from 'clipboard';


const AppDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { lang } = getUrlkey(location.search || "");

  const { appId } = useParams();
  const [detail, setDetail] = useState(undefined);
  const [sharePopupVisible, setSharePopupVisible] = useState(false);

  const [downloadCount, setDownloadCount] = useState(0);

  useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 useEffect(() => {
  if(detail){
    copyFun()
  }
 }, [detail])
 
  const getDetail = async () => {
    const params = lang ? {
      app_id: appId,
      lang_code: lang
    } : {
      app_id: appId,
    }
    const res = await api.getAppDetail({ ...params });
    if (res.code === 200) {
      setDetail(res.data);
      setDownloadCount(res.data?.appInfo?.download_counts || 0)
    }
  }
  
  const saveImage = async () => { 
    if((window.navigator.userAgent.includes("appName/isany"))||window.navigator.userAgent.includes("Lark")) return Toast.show({
      content: lang?getLang(lang)["savingFromBrowser"]:t('仅支持从浏览器打开保存'),
    })
    QRCode1.toDataURL(detail.appInfo.share_link)
  .then((url) => {
    mergeImage(url, `${detail.appInfo.app_name}.png`, lang);
  })
  }
  const copyFun=() =>{
    let clipboard = new Clipboard('#copyBtn', {
      text: function () {
        return detail.appInfo.share_link;
      },
    });
    clipboard.on('success', (e) => {
      e.clearSelection();
      clipboard.destroy();
      Toast.show({
        content: lang?getLang(lang)["successfullyCopiedLink"]:t('复制成功'),
      })
      setSharePopupVisible(false);
    });
    // 复制失败
    clipboard.on('error', (e) => {
      console.log(e,'ddd');
      clipboard.destroy();
    });
  }
  const copylink = () => {
    setSharePopupVisible(false);
    if (getOS() === "ios" || getOS() === "mac") {
      navigator.clipboard.writeText(detail.appInfo.share_link);
      Toast.show({
        content: lang?getLang(lang)["successfullyCopiedLink"]:t('复制成功'),
      })
    } else {
      var save = function (e) {
        e.clipboardData.setData('text/plain', detail.appInfo.share_link);
        e.preventDefault();//阻止默认行为
        Toast.show({
          content: lang?getLang(lang)["successfullyCopiedLink"]:t('复制成功'),
        })
        document.removeEventListener('copy', save);
      }
      document.addEventListener('copy', save);
      document.execCommand("copy");//使文档处于可编辑状态，否则无效
    }
  }

  const showCreateQrcode = () => {
    // setSharePopupVisible(false);
    // loadImage(detail.appInfo.app_icon, (data) => {
    //   console.log(data);
    // })
    Dialog.show({
      content: <div>
        <div className="qrcode-dialog-title-line">
          <div>
            <Image
              src={SanyLogoRed}
              fit='cover' />
          </div>
          <CloseOutline onClick={closeShareQrcode} />
        </div>
        <div className="dialog-content-wrap">
          <div id="qrcode" style={{ padding: '10px', backgroundColor: 'white' }}>
            <QRCode
              value={detail.appInfo.share_link}
              size={168}
              logo={detail.appInfo.app_icon}
              logoWidth={40}
              logoHeight={40} />
          </div>

          <div className="qrcode-tip">{lang?getLang(lang)["mobilePhoneScanningQRcodeDownloadApplication"]:t('手机扫描二维码下载应用')}</div>
        </div>
        <div className="dialog-button-line">
          <div className="dialog-button-cancel" onClick={closeShareQrcode}><div>{lang?getLang(lang)["cancel"]:t('取消')}</div></div>
          <div className="dialog-button-save" onClick={saveImage}><div>{lang?getLang(lang)["saveToAlbum"]:t('保存到相册')}</div></div>
        </div>
      </div>,
      closeOnMaskClick: true,
      actions: [],
      onConfirm: () => {
        console.log('Confirmed')
      },
    })
  }

  const closeShareQrcode = () => {
    Dialog.clear();
  }

  const goVersionList = () => {
    history.push(`/version-list/${appId}`);
  }

  const downloadAdd = () => {
    setDownloadCount(downloadCount + 1);
  }

  return <div>
    {
      detail && <div className="detail-wrap">
        <div className="detail-head-wrap">
          <div className="detail-head-icon">
            <Image
              src={detail?.appInfo.app_icon}
              fit='cover'
            />
          </div>
          <div className="detail-head-info">
            <div className="detail-head-app-name">{detail.appInfo.app_name}</div>
            <Ellipsis content={detail.appInfo.simple_desc} className="detail-head-download-num" />
            <div className="detail-head-download-num"><ArrowDownCircleOutline />{numberFormat(downloadCount, lang)}</div>
            <div className="detail-head-operate-line">
              <DownloadButton platforms={detail?.platforms} appId={detail.appInfo.app_id} downloadAdd={downloadAdd} lang={lang} />
              <div className="detail-head-share-button" onClick={() => { setSharePopupVisible(true) }}>
                <Image
                  src={ShareIcon}
                  fit='cover'
                />
              </div>
            </div>
          </div>
        </div>
        <div className="detail-desc" dangerouslySetInnerHTML={{ __html: detail.appInfo.detail }}></div>
        <div className="detail-lastversion">
          <div className="detail-lastversion-title-wrap">
            <div className="detail-lastversion-title">{lang?getLang(lang)["newFunction"]:t('新功能')}</div>
            <div className="detail-lastversion-more" onClick={goVersionList}>{lang?getLang(lang)["historicalVersion"]:t('历史版本')}</div>
          </div>
          <div className="detail-lastversion-title-wrap">
            {
              //{getLang(lang)["version"]}
            }
            <div className="detail-lastversion-value">{detail.last_version.version_name}<span> | {detail.last_version.platform} | {detail.last_version.platform_type}</span></div>
            <div className="detail-lastversion-value">{calcTime(detail.last_version.platform_update_time, lang)}</div>
          </div>
          <div className="detail-lastversion-desc">{detail.last_version.platform_detail}</div>
        </div>
      </div>
    }
    <Popup
      visible={sharePopupVisible}
      onMaskClick={() => {
        setSharePopupVisible(false)
      }}
      bodyStyle={{
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        minHeight: '190px',
      }}
    >
      <div>
        <div className="share-title-line">
          <div>{lang?getLang(lang)["share"]:t('分享')}</div>
          <CloseOutline onClick={() => { setSharePopupVisible(false) }} />
        </div>
        <div className="share-button-wrap">
          <div className="share-button-item" id="copyBtn">
            <div className="share-button-item-icon">
              <Image
                src={ShareCopyLinkIcon}
                fit='cover'
              />
            </div>
            <div className="share-button-item-text">{lang?getLang(lang)["copyLink"]:t('复制链接')}</div>
          </div>
          <div className="share-button-item" onClick={showCreateQrcode}>
            <div className="share-button-item-icon">
              <Image
                src={ShareCreateQrcodeIcon}
                fit='cover'
              />
            </div>
            <div className="share-button-item-text">{lang?getLang(lang)["generateQRcode"]:t('生成二维码')}</div>
          </div>
        </div>
      </div>
    </Popup>

  </div>
}
export default withRouter(withTranslation()(AppDetail));