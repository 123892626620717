import { Modal, Popup, Toast } from "antd-mobile";
import { CloseOutline } from "antd-mobile-icons";
import api from "api";
import React, { useEffect, useState } from "react";
import { getOS, isHuaWei, isWechat } from "utils/utils";
import { getLang } from "config/regionlang";
import { useTranslation } from "react-i18next";
import "./index.css"

const ChairmanDownloadButton = (props) => {
  const { platforms, appId, downloadAdd, lang = "zh_CN" } = props;
  const { t } = useTranslation();

  const [platformList, setPlatformList] = useState([]);

  const [webPlatformList, setWebPlatformList] = useState([]);

  const [platformsPopupVisible, setPlatformsPopupVisible] = useState(false);
  const [showWachatTip, setWachatTip] = useState(false)

  useEffect(() => {
    const platformList = [];
    const platformType = getOS();
    const webPlatformList = [];
    platforms && platforms.forEach(item => {
      if (item.platform_type === platformType) {
        platformList.push(item);
      } else if (item.platform_type === "webapp") {
        webPlatformList.push(item)
      }
    });
    setPlatformList(platformList);
    setWebPlatformList(webPlatformList);
  }, [platforms]);

  const installApp = (e) => {
    if (platformList.length === 0) {
      if (webPlatformList.length === 0) {
        Toast.show({
          content: lang?getLang(lang)["installationPackageTips"]:t("未上传安装包"),
        })
        return;
      } else if (webPlatformList.length === 1) {
        openWebApp(webPlatformList[0]);
      } else {
        if (isWechat()) {
          setWachatTip(true)
        } else if (getOS() === 'android') {
          showModalTip().then(() => { setPlatformsPopupVisible(true); })
        } else {
          setPlatformsPopupVisible(true);
        }
      }
    } else if (platformList.length === 1) {
      if (isWechat()) {
        setWachatTip(true)
      } else if (getOS() === 'android') {
        showModalTip().then(() => { downloadApp(platformList[0]); })
      } else {
        downloadApp(platformList[0]);
      }

    } else {
      if (isWechat()) {
        setWachatTip(true)
      } else if (getOS() === 'android') {
        showModalTip().then(() => { setPlatformsPopupVisible(true); })
      } else {
        setPlatformsPopupVisible(true);
      }
    }
    e.stopPropagation();
  }

  const showModalTip = () => {
    return new Promise((resolve) => {
      if (isHuaWei()) {
        Modal.show({
          header: <div><span className="install-tip-title">{lang?getLang(lang)["installationTips"]:t("安装提示")}</span></div>,
          content: <div>
            <div className="install-tip-desc" style={{ marginBottom: '28px' }}>{lang?getLang(lang)["installationTipsHW0"]:t("华为手机需要关闭“纯净模式”才能下载该应用，步骤如下：")}</div>
            <div className="install-tip-step">
              <div className="install-tip-step-wrap">
                <div className="install-tip-step-no">1</div>
                <div className="install-tip-step-content">{lang?getLang(lang)["installationTipsHW1"]:t("打开手机“设置”-“模式”")}</div>
              </div>
              <div className="install-tip-line"></div>
            </div>
            <div>
              <div className="install-tip-step-wrap">
                <div className="install-tip-step-no">2</div>
                <div className="install-tip-step-content">{lang?getLang(lang)["installationTipsHW2"]:t("将”纯净模式“关闭")}</div>
              </div>
              <div className="install-tip-line"></div>
            </div>
            <div>
              <div className="install-tip-step-wrap">
                <div className="install-tip-step-no">3</div>
                <div className="install-tip-step-content">{lang?getLang(lang)["installationTipsHW3"]:t("再回到”三一应用市场“重新安装应用")}</div>
              </div>
            </div>

          </div>,
          closeOnMaskClick: true,
          actions: [{
            key: 'kown',
            className: "modal-sure",
            text: lang?getLang(lang)["iKnow"]:t("我知道了"),
            onClick: () => { Modal.clear(); resolve() }
          }],
        })
      } else {
        Modal.show({
          header: <div><span className="install-tip-title">{lang?getLang(lang)["installationTips"]:t("安装提示")}</span></div>,
          content: <div className="install-tip-desc" style={{ marginBottom: '20px' }}>
            {lang?getLang(lang)["installationTips0"]:t("如遇无法安装情况，请检查手机权限设置；")}<br />
            {lang?getLang(lang)["installationTips1"]:t("部分安卓手机需要允许第三方App安装权限才能下载该应用。")}
          </div>,
          closeOnMaskClick: true,
          actions: [{
            key: 'kown',
            className: "modal-sure",
            text: lang?getLang(lang)["iKnow"]:t("我知道了"),
            onClick: () => { Modal.clear(); resolve() }
          }],
        })
      }
    })
  }

  const downloadApp = async (platform) => {
    let url = "";
    if (platform.platform_type === "ios") {
      if (platform.use_appstore === "1") {
        if (platform.abm_flag === "1") {
          // abm分发
          const res = await api.getAbmCode({ app_id: appId });
          if (res.code === 200) {
            url = res.data;
          } else {
            // abm获取失败
            Toast.show({
              content: res.msg,
            })
            return;
          }
        } else {
          // 普通分发
          url = `itms-services://?action=download-manifest&url=${platform.ios_plist_url}`;
        }
      } else {
        // appstore分发
        url = platform.appstore_url;
      }
    } else {
      url = platform.app_file_url;
    }
    await api.downloadHistoryAdd({
      app_id: appId,
      platform: platform.platform,
      platform_type: platform.platform_type
    }, { loading: true });
    if (downloadAdd) {
      downloadAdd();
    }
    // const a = document.createElement("a");
    // a.href = url;
    // a.target = "_blank";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    window.location.href = url;
    Toast.show({
      content: platform.platform_type === "ios" ? lang?getLang(lang)["goAppStoreViewProgress"]:t("请前往AppStore查看进度") :  lang?getLang(lang)["goBrowserViewDetail"]:t("请前往系统浏览器查看下载详情"),
    })
  }

  const openWebApp = (platform) => {
    const link = document.createElement('a');
    document.getElementById(`button_${appId}`).appendChild(link);
    link.href = platform.webapp_url;
    link.target = "_blank";
    link.onclick = (e) => {
      e.stopPropagation()
    }
    link.click();
    link.remove();
  }


  return <>
    <div className="chairman-download-button" onClick={installApp} id={`button_${appId}`}>
      <div className="chairman-download-button-text">{lang?(platformList.length > 0 ? getLang(lang)["install"] : (webPlatformList.length > 0 ? getLang(lang)["open"] : getLang(lang)["detail"])):(platformList.length > 0 ? t('安装') : (webPlatformList.length > 0 ? t('打开') :  t('详情')))}</div>
    </div>
    <Popup
      visible={platformsPopupVisible}
      onMaskClick={() => {
        setPlatformsPopupVisible(false)
      }}
      bodyStyle={{
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        minHeight: '40vh',
      }}
    >
      <div>
        <div className="chairman-download-title-line">
          <div>{lang?getLang(lang)["download"]:t("下载")}</div>
          <CloseOutline onClick={() => { setPlatformsPopupVisible(false) }} />
        </div>
        <div className="chairman-download-button-wrap">
          <div className="chairman-download-tip">{lang?getLang(lang)["moreVersionTip"]:t("此应用程序有多个版本，请选择相应的版本")}</div>
          {
            platformList && platformList.length > 0 ? platformList.map((item, index) => {
              return <div className="chairman-download-button-item" key={index} onClick={() => { downloadApp(item); setPlatformsPopupVisible(false) }}>{item.platform}</div>
            }) : <>
              {
                webPlatformList && webPlatformList.length > 0 ? webPlatformList.map((item, index) => {
                  return <div className="chairman-download-button-item" key={index} onClick={() => { openWebApp(item); setPlatformsPopupVisible(false) }}>{item.platform}</div>
                }) : <></>
              }
            </>
          }
        </div>
      </div>
    </Popup>
    {
      showWachatTip && <div className="wechat-mask">
      </div>
    }
  </>
}

export default ChairmanDownloadButton