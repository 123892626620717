import { Toast } from "antd-mobile";
import { getLang } from "config/regionlang";
import { t } from "i18next";
import { isAndroid, isIOS, isMacOs, isWindows } from "react-device-detect";

export function getSysLanguage() {
  const langSelect = localStorage.getItem('lang-select') || 'follow'
  if (langSelect === 'follow') {
    if (navigator.userAgent.includes("language/en")) {
      return "en";
    } else if (navigator.userAgent.includes("language/zh-Hans")) {
      return "zh";
    } else {
      return navigator.language.includes("zh") ? "zh" : "en";
    }
  } else {
    return langSelect
  }
}

export function downloadAndInstallAPK(url) {
  var webView = document.createElement('webview');
  document.body.appendChild(webView);
  webView.src = url;
  webView.addEventListener('loadend', function () {
    if (webView.src.startsWith('blob:')) {
      var apkData = atob(webView.src.split(',')[1]);
      var apkFileName = url.split("/").pop();
      var file = new Blob([apkData], { type: 'application/vnd.android.package-archive' });
      var fileUrl = URL.createObjectURL(file);
      webView.parentNode.removeChild(webView);
      var confirmDownload = window.confirm(`Do you want to install ${apkFileName}?`);
      if (confirmDownload) {
        window.location.href = fileUrl;
      }
    }
  });
}
export function getCookieValue(cookieName) {
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + "=")) {
      return cookie.substring(cookieName.length + 1);
    }
  }
  return null; // 如果找不到指定的 Cookie，则返回 null 或其他适当的值
}
// [10, 8, 56, 45, 3, 94, 75, 64, 18, 59, 42, 53, 7]
export function partition(array, low, high) {
  let pivot = array[low];
  let i = low;
  let j = high;
  while (i < j) {
    while (i < j && array[j] >= pivot) {
      j--;
    }
    array[i] = array[j];
    while (i < j && array[i] <= pivot) {
      i++;
    }
    array[j] = array[i];
  }
  array[i] = pivot;
  return i;
}

export function quickSort(array, low, high) {
  if (low < high) {
    let loc = partition(array, low, high);
    quickSort(array, low, loc - 1);
    quickSort(array, loc + 1, high);
  }
}

export async function mergeImage(image, name, lang) {
  let canvas1 = document.createElement("canvas");
  canvas1.width = 375;
  canvas1.height = 667;
  let context1 = canvas1.getContext("2d");
  context1.rect(0, 0, canvas1.width, canvas1.height);
  context1.fillStyle = "#fff";
  context1.fill();
  var myImage = new Image();
  myImage.src = require("assets/bg-qrcode.png");
  myImage.crossOrigin = 'Anonymous';
  myImage.onload = function () {
    context1.drawImage(myImage, 0, 0, 375, 667);
    var myImage2 = new Image();
    myImage2.src = image;
    myImage2.crossOrigin = 'Anonymous';
    myImage2.onload = function () {
      context1.drawImage(myImage2, 50, 196, 275, 275);
      var base64 = canvas1.toDataURL("image/png");
      downloadFileByBase64(base64, name)
      Toast.show({
        content: lang ? getLang(lang)["successfulalbumSaving"] : t('保存相册成功'),
      })
    }
  }
}

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

function downloadFile(url, name) {
  var a = document.createElement("a") //新建一个a链接
  a.setAttribute("href", url) // a链接的url为图片的url
  a.setAttribute("download", name)
  a.click()
  window.URL.revokeObjectURL(url);
}

function downloadFileByBase64(base64, name) {
  var myBlob = dataURLtoBlob(base64);
  var myUrl = URL.createObjectURL(myBlob); //创建图片的临时url
  downloadFile(myUrl, name)
}

export function numberFormat(number, lang) {
  // if (number > 100000000) {
  //   return `${(number / 100000000).toFixed(2)}${lang ? getLang(lang)["B"] : t("亿")}`
  // } else if (number > 10000) {
  //   return `${(number / 10000).toFixed(2)}${lang ? getLang(lang)["W"] : t("万")}`
  // } else {
  //   return `${number}`
  // }
  const currentLang = lang || getSysLanguage()
  if (currentLang.includes("zh")) {
    if (number > 100000000) {
      return `${(number / 100000000).toFixed(2)}亿`
    } else if (number > 10000) {
      return `${(number / 10000).toFixed(2)}万`
    } else {
      return `${number}`
    }
  } else {
    return number.toLocaleString();
  }
}

export function getOS() {
  var os = "windows";
  if (isIOS) {
    os = 'ios';
  } else if (isMacOs) {
    if (window.navigator.platform.toLowerCase().includes('mac')) {
      os = 'mac';
    } else {
      os = 'ios';
    }
  } else if (isAndroid || window.navigator.userAgent.toLowerCase().includes("android")) {
    os = 'android';
  } else if (isWindows) {
    os = 'windows';
  }
  return os;
}

export function isWechat() {
  if (window.navigator.userAgent.toLowerCase().includes('micromessenger')) {
    return true;
  } else {
    return false;
  }
}

export function isHuaWei() {
  if (window.navigator.userAgent.toLowerCase().includes('huawei')) {
    return true;
  } else {
    return false;
  }
}

export function debounce(fn, delay, immediate = false) {
  let isInvoke = false;
  let timer = null;
  const _debounce = function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    if (immediate && !isInvoke) {
      fn.apply(this, args);
      isInvoke = true;
    } else {
      timer = setTimeout(() => {
        fn.apply(this, args);
        isInvoke = false;
      }, delay)
    }
  }
  _debounce.cancel = function () {
    if (timer) clearTimeout(timer);
    timer = null;
    isInvoke = false;
  }
  return _debounce
}

export function sliceUrl(url) {
  return '/' + url.split('/').slice(3).join('/');

}

export function getUrlkey(url) {
  let params = {};
  let urls = url.split("?");
  if (urls.length > 1) {
    let arr = urls[1].split("&");
    for (let i = 0, l = arr.length; i < l; i++) {
      let index = arr[i].indexOf("=");
      let key = arr[i].slice(0, index);
      let value = arr[i].slice(index + 1, arr[i].length);
      params[key] = value;
    }
  }
  // console.log(params);
  return params;
};

export function calcTime(startTimeString, lang) {
  let timeString = startTimeString;
  let currentTime = new Date().getTime();
  let startTime = new Date(timeString).getTime();
  if (startTime < currentTime) {
    if ((currentTime - startTime) < (24 * 60 * 60 * 1000)) {
      if ((currentTime - startTime) / (60 * 60 * 1000) < 1) {
        if ((currentTime - startTime) / (60 * 1000) < 1) {
          return lang ? getLang(lang)["just"] : t('刚刚');
        } else {
          return Math.floor((currentTime - startTime) / (60 * 1000)) + (lang ? getLang(lang)["minutesAgo"] : t("分钟前"));
        }
      } else {
        return Math.floor((currentTime - startTime) / (60 * 60 * 1000)) + (lang ? getLang(lang)["hoursAgo"] : t("小时前"));
      }
    } else {
      return timeString.slice(0, timeString.length - 3)
    }
  } else if (startTime - currentTime < 2 * 60 * 1000) {
    return lang ? getLang(lang)["just"] : t('刚刚');
  } else {
    return timeString.slice(0, timeString.length - 3);
  }

};
