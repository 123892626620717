import "./App.css";
import { HashRouter, Redirect, Switch } from "react-router-dom";
import { ConfigProvider } from "antd-mobile";
import { renderRoutes } from "./utils/renderRouter";
import routerConfig from "./router";
import VConsole from "vconsole";
import { useEffect, useState } from "react";
import i18n from "./locales/i18n";
import { t } from "i18next";
import enUS from 'antd-mobile/es/locales/en-US'
import zhCN from 'antd-mobile/es/locales/zh-CN'

function App() {
  const [localeLang, setLocaleLang] = useState('zh')
  useEffect(() => {
    const langSelect = localStorage.getItem('lang-select') || 'follow'
    if (langSelect !== 'follow') {
      i18n.changeLanguage(langSelect);
      document.title = t("三一应用市场")
      setLocaleLang(langSelect)
    } else {
      if (navigator.language) {
        let systemLanguage = navigator.language.includes("zh") ? "zh" : "en"
        i18n.changeLanguage(systemLanguage);
        document.title = t("三一应用市场");
        localStorage.setItem('lang-select', 'follow')
        setLocaleLang(systemLanguage)
      }
    }
    if (process.env.REACT_APP_ENV === 'test') {
      const vconsole = new VConsole();
      console.log(vconsole);
    }
  }, [])

  return (
    <ConfigProvider locale={localeLang.includes("zh") ? zhCN : enUS}>
      <HashRouter>
        <Switch>
          <Redirect exact path="/" to="/home/app-list" />
          <Redirect exact path="/home" to="/home/app-list" />
          {renderRoutes(routerConfig)}
        </Switch>
      </HashRouter>
    </ConfigProvider>
  );
}

export default App;
