import React, { useEffect, useState } from 'react'
import { withTranslation } from "react-i18next";
import "./index.css";
import { useHistory, useLocation } from "react-router-dom";
import { SearchBar, TabBar, Picker, Button } from "antd-mobile";
import { useTranslation } from "react-i18next";
import { GlobalOutline } from 'antd-mobile-icons'
import { renderRoutes } from "../../utils/renderRouter";

const basicColumns = [
  [
    { label: '周一', value: 'Mon' },
    { label: '周二', value: 'Tues' },
    { label: '周三', value: 'Wed' },
    { label: '周四', value: 'Thur' },
    { label: '周五', value: 'Fri' },
  ],
  [
    { label: '上午', value: 'am' },
    { label: '下午', value: 'pm' },
  ],
]
const Home = (props) => {
  const { t } = props;
  const { i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation()
  const { pathname } = location
  const [langVisible, setLangVisible] = useState(false)
  const [language, setLanguage] = useState(["follow"])
  const langOption = [[{ label: t('跟随系统'), value: 'follow' }, { label: '简体中文', value: 'zh' }, { label: 'English', value: 'en' }]]
  useEffect(() => {
    const langSelect = localStorage.getItem('lang-select') || 'follow'
    setLanguage([langSelect])
  }, [])
  const tabs = [
    {
      key: '/home/app-list',
      title: t('首页'),
      icon: <div className="iconfont icon-home" style={{ fontSize: '20px' }} />,
    },
    {
      key: '/home/new-user',
      title: t('新人必备'),
      icon: <>{pathname === '/home/new-user' ? <div className="iconfont icon-dengpao-tianchong" style={{ fontSize: '20px' }} /> : <div className="iconfont icon-dengpao" style={{ fontSize: '20px' }} />}</>,
    },
  ]

  const setRouteActive = (value) => {
    history.push(value)
  }

  const goSearch = () => {
    history.push("/search-list")
  }

  const changeLanguage = (value) => {
    setLanguage(value)
    localStorage.setItem('lang-select', value[0])
    if (value[0] === 'follow') {
      const systemLang = navigator.language.includes("zh") ? "zh" : "en";
      i18n.changeLanguage(systemLang)
    } else {
      i18n.changeLanguage(value[0])
    }
    window.location.reload()
  }
  return (
    <div className="home">
      <div className="search-head">
        <SearchBar placeholder={t('请输入APP名称')} className="search-head-search" disabled onFocus={goSearch} />
        <div className='lang-head'>
          <Button
            onClick={() => {
              setLangVisible(true)
            }}
            fill='none'
            style={{fontSize:14,color:'#666'}}
          >
            <GlobalOutline fontSize={16} style={{marginRight:6}}/>
            {langOption[0].find(el => el.value === language[0]).label}
          </Button>
          <Picker
            visible={langVisible}
            onClose={() => {
              setLangVisible(false)
            }}
            columns={langOption}
            value={language}
            onConfirm={changeLanguage}
          />
        </div>
      </div>
      <div className="home-body">
        {renderRoutes(props.routes || [])}
      </div>
      <div className="home-bottom">
        <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
          {tabs.map(item => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
    </div>
  )

}
export default withTranslation()(Home);