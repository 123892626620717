import { Toast } from "antd-mobile";
import Axios from 'axios';
import qs from 'qs';
import { t } from "i18next";
import axios from "axios";
import fileDownload from "js-file-download";
import { getSysLanguage } from "utils/utils";

const baseURL = "/sany-store-api";

const toast = (type, message, duration) => {
	Toast.show({
		icon: type,
		content: message,
		duration,
	});
};
const failToast = (message) => {
	toast("fail", message, 2000);
};
const loadingToast = (message) => {
	Toast.show({
		icon: "loading",
		content: message,
		duration: 0,
		maskClickable: false,
	});
};
const closeLoading = () => {
	Toast.clear();
};

const instance = Axios.create({
	baseURL: baseURL,
	withCredentials: true,
	timeout: 30000,
});

// 取消重复请求start
function generateReqKey(config) {
	const { method, url, params, data } = config;
	return [method, url, qs.stringify(params), qs.stringify(data)].join("&");
}

const pendingRequest = new Map();
function addPendingRequest(config) {
	const requestKey = generateReqKey(config);
	config.cancelToken =
		config.cancelToken ||
		new Axios.CancelToken((cancel) => {
			if (!pendingRequest.has(requestKey)) {
				pendingRequest.set(requestKey, cancel);
			}
		});
}

function removePendingRequest(config) {
	const requestKey = generateReqKey(config);
	if (pendingRequest.has(requestKey)) {
		const cancelToken = pendingRequest.get(requestKey);
		cancelToken(requestKey);
		pendingRequest.delete(requestKey);
	}
}
// 取消重复请求end

instance.interceptors.request.use(
	(config) => {
		// repeat_request：是否允许重复请求
		removePendingRequest(config);
		!config.repeat_request && addPendingRequest(config);
		config.loading && loadingToast(t("加载中"));
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(res) => {
		res.config.loading && closeLoading();
		switch (res.data.code) {
			case 200:
				return res;
			default:
				failToast(res.data.msg);
				return Promise.reject(res);
		}
	},
	(err) => {
		closeLoading();
		removePendingRequest(err.config || {});
		if (Axios.isCancel(err)) {
			console.log("已取消的重复请求：" + err.msg);
		} else {
			failToast(t("网络异常"));
		}
		return Promise.reject(err);
	}
);



/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}, config) {
	return new Promise((resolve, reject) => {
		instance
			.get(url, {
				params: params,
        headers: {
          'Lang-Code': getSysLanguage()
        },
				...config,
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, params = {}, config) {
	return new Promise((resolve, reject) => {
		instance.post(url, params, config).then(
			(response) => {
				resolve(response.data);
			},
			(err) => {
				reject(err);
			}
		);
	});
}

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function del(url, params = {}, config) {
	return new Promise((resolve, reject) => {
		instance
			.delete(url, {
				params: params,
				...config,
			})
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, params = {}, config) {
	return new Promise((resolve, reject) => {
		instance.put(url, params, config).then(
			(response) => {
				resolve(response.data);
			},
			(err) => {
				reject(err);
			}
		);
	});
}

export function downloadFile(method = "get", url, params = {}, filename) {
	let key = method === "get" ? "params" : "data";
	let config = {
		withCredentials: true,
		timeout: 30000,
		method: method,
		url: url,
		responseType: "blob",
		[key]: params,
		onDownloadProgress(ProgressEvent) {
			const load = ProgressEvent.loaded
			const total = ProgressEvent.total
			const progress = (load / total) * 100
			console.log('progress=' + progress)
			//一开始已经在计算了 这里要超过先前的计算才能继续往下
			// if (progress > _this.fileDown.percentage) {
			//   _this.fileDown.percentage = Math.floor(progress)
			// }
			if (progress === 100) {
				//下载完成
				console.log(ProgressEvent)
			}
		},
	};
	axios(config).then((response) => {
		let reader = new FileReader();
		reader.readAsText(response.data);
		reader.onload = function () {
			let content = reader.result;
			if (response.data.type === "application/json") {
				Toast(JSON.parse(content).msg);
			} else {
				fileDownload(response.data, filename);
			}
		};
	});
}

export function loadImage(url, loadFinish) {
	let config = {
		withCredentials: true,
		timeout: 30000,
		method: "get",
		url: url,
		responseType: "blob",
	};
	axios(config).then((response) => {
		let reader = new FileReader();
		reader.readAsText(response.data);
		reader.onload = function () {
			let content = reader.result;
			if (response.data.type === "application/json") {
				Toast(JSON.parse(content).msg);
			} else {
				if (loadFinish) {
					loadFinish(response.data)
				}
			}
		};
	});
}


export default instance;
