import api from "api";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { calcTime } from "utils/utils";
import "./index.css"

const VersionList = () => {
  const { appId } = useParams();
  const [versionList, setVersionList] = useState([]);
  useEffect(() => {
    getVersionList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getVersionList = async () => {
    const res = await api.getAppVersionList({
      app_id: appId,
    })
    if (res.code === 200) {
      setVersionList(res.rows);
    }
  }
  return <div>
    {
      versionList && versionList.map((item, index) => {
        return <div key={index} className="version-item">
          <div className="version-item-title">
            <div className="version-item-name">{item.version_name}<span style={{ color: "#666666", fontWeight: 400 }}> | {item.platform} | {item.platform_type}</span></div>
            <div className="version-item-date">{calcTime(item.publish_time)}</div>
          </div>
          <div className="version-item-detail">{item.detail}</div>
        </div>
      })
    }
  </div>
}
export default withTranslation()(VersionList)