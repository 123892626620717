import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector"

import resources from "./languagepackages/resources"

function getCookieValue(cookieName) {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.startsWith(cookieName + "=")) {
        return cookie.substring(cookieName.length + 1);
      }
    }
    return null; // 如果找不到指定的 Cookie，则返回 null 或其他适当的值
  }


i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
    fallbackLng:"en",
    lng:getCookieValue("lang") || "en",
    debug:true,
    resources:resources,
    interpolation:{
        escapeValue:false,
    }
});

export default i18n;