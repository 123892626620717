export const regionLang = {
  zh_CN: {
    install: "安装",
    detail: "详情",
    open: "打开",
    necessary: "必备",
    newFunction: "新功能",
    historicalVersion: "历史版本",
    version: "版本",
    share: "分享",
    copyLink: "复制链接",
    generateQRcode: "生成二维码",
    successfullyCopiedLink: "复制链接成功",
    cancel: "取消",
    mobilePhoneScanningQRcodeDownloadApplication: "手机扫描二维码下载应用",
    W: "万",
    B: "亿",
    download: "下载",
    moreVersionTip: "此应用程序有多个版本，请选择相应的版本",
    just: "刚刚",
    hoursAgo: "小时前",
    minutesAgo: "分钟前",
    goAppStoreViewProgress: "请前往AppStore查看进度",
    goBrowserViewDetail: "请前往系统浏览器查看下载详情",
    installationTips: "安装提示",
    installationTipsHW0: "华为手机需要关闭“纯净模式”才能下载该应用，步骤如下：",
    installationTipsHW1: "打开手机“设置”-“模式”",
    installationTipsHW2: "将”纯净模式“关闭",
    installationTipsHW3: "再回到”三一应用市场“重新安装应用",
    installationTips0: "如遇无法安装情况，请检查手机权限设置；",
    installationTips1: "部分安卓手机需要允许第三方App安装权限才能下载该应用。",
    iKnow: "我知道了",
    saveToAlbum: "保存到相册",
    installationPackageTips: "未上传安装包",
    savingFromBrowser: "仅支持从浏览器打开保存",
    successfulalbumSaving: "保存相册成功"
  },
  ru_RU: {
    install: "Монтаж",
    detail: "Подробности",
    open: "Открыть",
    necessary: "Обязательно",
    newFunction: "Новые функции ",
    historicalVersion: "Историческая версия",
    version: "Версия",
    share: "Поделиться",
    copyLink: "Копировать ссылку",
    generateQRcode: "Создание QR - кода",
    successfullyCopiedLink: "Скопировать ссылку успешно",
    cancel: "Отменить",
    mobilePhoneScanningQRcodeDownloadApplication: "Сканирование мобильного QR - кода скачать приложение",
    W: "W",
    B: "B",
    download: "Скачать",
    moreVersionTip: "Приложение имеет несколько версий, выберите соответствующую версию",
    just: "Только что",
    hoursAgo: "Часы назад",
    minutesAgo: "Минуту назад",
    goAppStoreViewProgress: "Пожалуйста, посетите AppStore, чтобы увидеть прогресс.",
    goBrowserViewDetail: "Обратитесь в системный браузер, чтобы узнать подробности загрузки.",
    installationTips: "Советы по установке",
    installationTipsHW0: "Телефоны Huawei должны выключить «Чистый режим», чтобы загрузить приложение, выполните следующие действия:",
    installationTipsHW1: "Откройте «Настройки» вашего телефона - «Режим»",
    installationTipsHW2: "Отключите «Чистый режим»",
    installationTipsHW3: "Вернитесь в «Sany App Market» и переустановите приложение",
    installationTips0: "В случае сбоя установки, пожалуйста, проверьте настройки разрешений вашего телефона;",
    installationTips1: "Для некоторых телефонов Android требуется разрешение, позволяющее стороннему приложению загружать приложение.",
    iKnow: "Я знаю",
    saveToAlbum: "Сохранить в альбом",
    installationPackageTips: "Не загружен пакет установки",
    savingFromBrowser: "Поддерживается только открытие и сохранение из браузера.",
    successfulalbumSaving: "Успешное сохранение фотоальбома."
  },
  en_US: {
    install: "Install",
    detail: "Detail",
    open: "Open",
    necessary: "Necessary",
    newFunction: "NewFunction",
    historicalVersion: "HistoricalVersion",
    version: "Version",
    share: "Share",
    copyLink: "CopyLink",
    generateQRcode: "GenerateQRcode",
    successfullyCopiedLink: "SuccessfullyCopiedLink",
    cancel: "Cancel",
    mobilePhoneScanningQRcodeDownloadApplication: "MobilePhone Scanning QRcode Download Application",
    W: "W",
    B: "B",
    download: "Download",
    moreVersionTip: "There are multiple versions of this application, please select the corresponding version",
    just: "Just",
    hoursAgo: "HoursAgo",
    minutesAgo: "MinutesAgo",
    goAppStoreViewProgress: "Please go to the AppStore to check the progress",
    goBrowserViewDetail: "Please go to the system browser to view the download details",
    installationTips: "Installation Tips",
    installationTipsHW0: "Huawei phones need to turn off 'Pure Mode' to download the app, follow these steps:",
    installationTipsHW1: "Open your phone's 'Settings' - 'Mode'",
    installationTipsHW2: "Turn off 'Pure Mode'",
    installationTipsHW3: "Go back to 'Sany App Market' and reinstall the app",
    installationTips0: "In case of failure to install, please check the permission settings of your phone;",
    installationTips1: "Some Android phones require permission to allow third-party app installation to download the app.",
    iKnow: "OK",
    saveToAlbum: "Save to album",
    installationPackageTips: "The installation package has not been uploaded",
    savingFromBrowser: "Only support opening and saving from browser.",
    successfulalbumSaving: "Successful album saving."
  },
}
export function getLang(lang) {
  if (lang) {
    return regionLang[lang] || regionLang["zh_CN"]
  } else {
    return regionLang["zh_CN"]
  }
}