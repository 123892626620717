import { get, post } from "../http/httpClient";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // 获取App列表
  getAppList(params) {
    return get("/v1/home/applist", params, { loading: true });
  },
  // 获取app推荐列表
  getCommandList(params) {
    return get("/v1/command/list", params, {});
  },
  // 获取推荐分类所有App
  getCommandMore(params) {
    return get("/v1/command/more", params, {});
  },
  // 获取搜索热词
  getSearchHotwords(params) {
    return get("/v1/search/hotwords", params, {});
  },
  // 获取搜索列表 @params={condition}
  getSearchList(params) {
    return get("/v1/search/list", params, {});
  },
  // 获取App详情 @params={app_id}
  getAppDetail(params) {
    return get("/v1/app/detail", params, {});
  },
  // 获取App历史版本详情 @params={app_id}
  getAppVersionList(params) {
    return get("/v1/app/version/list", params, {});
  },
  // 获取app abm下载地址 @params={app_id}
  getAbmCode(params) {
    return get("/v1/app/abm_code", params, { loading: true });
  },
  // 新增下载记录 @params={app_id,platform,platform_type}
  downloadHistoryAdd(params, config) {
    return post("/v1/app/download/add", params, config);
  },
  getDaquList() {
    return get("/v1/group/region", {}, { loading: true })
  },
  getGroupAppById(id) {
    return get("/v1/group/detail", { group_id: id }, { loading: true })
  },
  getSearchRecommend() {
    return get("/v1/search/recommend", {}, {})
  }
}