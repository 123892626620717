import { Image } from "antd-mobile";
import { ArrowDownCircleOutline } from "antd-mobile-icons";
import api from "api";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { numberFormat } from "utils/utils";
import "./index.css";

const SharePage = () => {

  const { appId } = useParams();
  const [detail, setDetail] = useState(undefined);
  const [downloadCount, setDownloadCount] = useState(0);

  useEffect(() => {
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDetail = async () => {
    const res = await api.getAppDetail({
      app_id: appId,
    });
    if (res.code === 200) {
      setDetail(res.data);
      setDownloadCount(res.data?.appInfo?.download_counts || 0)
    }
  }
  return <div className="share-wrap">
    <div className="share-head-icon">
      <Image
        src={detail?.appInfo?.app_icon}
        fit='cover'
      />
    </div>
    <div className="share-app-name">{detail?.appInfo?.app_name}</div>
    <div className="share-info">
      <span><ArrowDownCircleOutline />{numberFormat(downloadCount)}</span>
      {' | '}
      <span>{detail?.appInfo?.upload_account}</span>
      {' | '}
      <span>{detail?.appInfo?.phonenumber}</span>
    </div>
    <div>
      {
        detail?.platforms && detail?.platforms.map((item, index) => {
          return <div key={index} className="btn">
            {item.platform}
          </div>
        })
      }
    </div>
  </div>
}
export default SharePage;