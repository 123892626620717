import { Ellipsis, Image, InfiniteScroll, PullToRefresh } from "antd-mobile";
import api from "api";
import AppItem from "components/app-item";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { numberFormat } from "utils/utils";
import IconTop1Top from "assets/icon-top1-top.svg";
import IconTop1Bottom from "assets/icon-top1-bottom.svg";
import IconTop2Top from "assets/icon-top2-top.svg";
import IconTop2Bottom from "assets/icon-top2-bottom.svg";
import IconTop3Top from "assets/icon-top3-top.svg";
import IconTop3Bottom from "assets/icon-top3-bottom.svg";
import "./index.css";
import { ArrowDownCircleOutline } from "antd-mobile-icons";
import DownloadButton from "components/download-button";

class AppList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appTop3List: [],
      appList: []
    }
  }

  componentDidMount() {
    const { action } = this.props.history;
    console.log(action);
    console.log(window.AState);
    if (action === "POP" && window.AState) {
      this.setState({
        ...window.AState
      }, () => {
        window.AState = undefined;
      })
    } else {
      window.AState = undefined
      this.getList();
    }
  }

  componentWillUnmount() {
    window.AState = this.state;
  }

  async getList() {
    const res = await api.getAppList();
    if (res.code === 200) {
      if (res.rows && res.rows.length > 3) {
        this.setState({
          appTop3List: res.rows.slice(0, 3),
          appList: res.rows.slice(3)
        })
      } else {
        this.setState({
          appTop3List: res.rows,
          appList: []
        })
      }

    }
  }
  goDetail(appId) {
    this.props.history.push(`/app-detail/${appId}`);
  }

  downloadAdd(index) {
    const list = this.state.appTop3List.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          appInfo: {
            ...item.appInfo,
            download_counts: (item.appInfo.download_counts || 0) + 1
          }
        };
      } else {
        return item;
      }
    })
    this.setState({
      appTop3List: list,
    })
  }

  render() {
    const { appTop3List, appList } = this.state;
    return <PullToRefresh
      onRefresh={async () => {
        await this.getList();
      }}
    >
      <div className="app-list">
        {
          appTop3List && appTop3List.length > 0 && <div className="app-list-top3-wrap">
            <div className="app-list-top3-content">
              {
                appTop3List.length > 1 && <div className="app-item-top2" onClick={() => { this.goDetail(appTop3List[1]?.appInfo.app_id) }}>
                  <div className="app-item-top2-icon-wrap">
                    <Image
                      src={appTop3List[1]?.appInfo.app_icon}
                      fit='cover'
                      className="app-item-top2-icon"
                    />
                    <Image
                      src={IconTop2Top}
                      fit='cover'
                      className="app-item-top2-icon-top"
                    />
                    <Image
                      src={IconTop2Bottom}
                      fit='cover'
                      className="app-item-top2-icon-bottom"
                    />
                  </div>
                  <Ellipsis direction='middle' content={appTop3List[1]?.appInfo.app_name} className="app-item-name" />
                  <div className="app-item-download-num"><ArrowDownCircleOutline />{numberFormat(appTop3List[1]?.appInfo.download_counts)}</div>
                  <DownloadButton platforms={appTop3List[1]?.platforms} appId={appTop3List[1].appInfo.app_id} downloadAdd={() => { this.downloadAdd(1) }} />
                </div>
              }
              {
                appTop3List.length > 0 && <div className="app-item-top1" onClick={() => { this.goDetail(appTop3List[0]?.appInfo.app_id) }}>
                  <div className="app-item-top1-icon-wrap">
                    <Image
                      src={appTop3List[0]?.appInfo.app_icon}
                      fit='cover'
                      className="app-item-top1-icon"
                    />
                    <Image
                      src={IconTop1Top}
                      fit='cover'
                      className="app-item-top1-icon-top"
                    />
                    <Image
                      src={IconTop1Bottom}
                      fit='cover'
                      className="app-item-top1-icon-bottom"
                    />
                  </div>
                  <Ellipsis direction='middle' content={appTop3List[0]?.appInfo.app_name} className="app-item-name" />
                  <div className="app-item-download-num"><ArrowDownCircleOutline />{numberFormat(appTop3List[0]?.appInfo.download_counts)}</div>
                  <DownloadButton platforms={appTop3List[0]?.platforms} appId={appTop3List[0].appInfo.app_id} downloadAdd={() => { this.downloadAdd(0) }} />
                </div>
              }
              {
                appTop3List.length > 2 && <div className="app-item-top3" onClick={() => { this.goDetail(appTop3List[2]?.appInfo.app_id) }}>
                  <div className="app-item-top2-icon-wrap">
                    <Image
                      src={appTop3List[2]?.appInfo.app_icon}
                      fit='cover'
                      className="app-item-top2-icon"
                    />
                    <Image
                      src={IconTop3Top}
                      fit='cover'
                      className="app-item-top2-icon-top"
                    />
                    <Image
                      src={IconTop3Bottom}
                      fit='cover'
                      className="app-item-top2-icon-bottom"
                    />
                  </div>
                  <Ellipsis direction='middle' content={appTop3List[2]?.appInfo.app_name} className="app-item-name" />
                  <div className="app-item-download-num"><ArrowDownCircleOutline />{numberFormat(appTop3List[2]?.appInfo.download_counts)}</div>
                  <DownloadButton platforms={appTop3List[2]?.platforms} appId={appTop3List[2].appInfo.app_id} downloadAdd={() => { this.downloadAdd(2) }} />
                </div>
              }
            </div>
          </div>
        }
        <div className="list-wrap">
          {
            appList?.map((item, index) => {
              return <AppItem data={item} key={index} />
            })
          }
        </div>
        <InfiniteScroll
          hasMore={false}
          loadMore={() => {
            console.log("loadMore");
          }}
        />
      </div>

    </PullToRefresh>
  }
}

export default withTranslation()(AppList);