import { Tabs } from "antd-mobile";
import api from "api";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import GroupContent from "./components/group-content";
import "./index.css";

class NewUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: "",
      groups: []
    }
  }

  componentDidMount() {
    const { action } = this.props.history;
    if (action === "POP" && window.NState) {
      this.setState({
        ...window.NState
      }, () => {
        window.NState = undefined;
      })
    } else {
      window.NState = undefined
      this.getDaqu();
    }
  }

  componentWillUnmount() {
    window.NState = this.state;
  }
  async getDaqu() {
    const res = await api.getDaquList();
    if (res.code === 200) {
      this.setState({
        activeKey: `${res.data[0]?.group_id}` || "",
        groups: res.data,
      })
    }
  }
  tabChange(value) {
    this.setState({
      activeKey: value,
    })
  }

  render() {
    const { activeKey, groups } = this.state;
    return <div>
      <Tabs stretch={false} activeKey={activeKey} onChange={(value) => this.tabChange(value)}>
        {
          groups.map((item) => {
            return <Tabs.Tab title={item.group_name} key={item.group_id}>
              <GroupContent appList={item.applist} lang={item.lang_code} groupName={item.group_name} />
            </Tabs.Tab>
          })
        }
      </Tabs>
    </div>
  }
}
export default withRouter(withTranslation()(NewUser));