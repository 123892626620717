import { SearchBar } from "antd-mobile";
import api from "api";
import AppItem from "components/app-item";
import React, { useEffect, useRef, useState } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./index.css";

let timer = null;
const SearchList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const searchBar = useRef(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchWordList, setSearchWordList] = useState([]);
  const [moreUseAppList, setMoreAppList] = useState([]);

  const [searchList, setSearchList] = useState([]);

  useEffect(() => {
    const { action } = history;
    if (action === "PUSH") {
      searchBar.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchBar]);

  useEffect(() => {
    getHotWordList();
    getMoreUseList();
  }, []);

  const getHotWordList = async () => {
    // setMoreAppList([]);
    const res = await api.getSearchHotwords();
    if (res.code === 200) {
      setSearchWordList(res.data?.hotwords || []);
    }
  }

  const getSearchList = async (value) => {
    setSearchWordList([]);
    setMoreAppList([]);
    const res = await api.getSearchList({
      condition: value
    });
    if (res.code === 200) {
      setSearchList(res.rows || []);
    }
  }

  const getMoreUseList = async () => {
    const res = await api.getSearchRecommend();
    if (res.code === 200) {
      setMoreAppList(res.data);
    }
  }

  const searchFocus = async () => {
    // getHotWordList()
  }

  const searchChange = (value) => {
    setSearchValue(value);
    if (value && value.length > 0) {
      if (timer !== null) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        // getHotWordList(value);
        getSearchList(value);
      }, 1000);
    } else {
      clearTimeout(timer);
      setSearchList([]);
      getHotWordList();
      getMoreUseList();
    }
  }

  const onSearch = async (value) => {
    getSearchList(value);
  }

  return <div>
    <div className="search-head">
      <SearchBar ref={searchBar} placeholder={t('请输入APP名称')} value={searchValue} className="search-head-search" clearable onFocus={searchFocus} onChange={searchChange} onSearch={onSearch} />
    </div>
    {
      searchWordList && searchWordList.length > 0 && <div>
        {
          searchWordList.map((item, index) => {
            return <div key={index} className="search-word-item" onClick={() => { onSearch(item.words); setSearchValue(item.words); }}>
              <div className="iconfont icon-search"></div>
              <div className="search-word-item-text">{item.words}</div>
            </div>
          })
        }
      </div>
    }
    {
      moreUseAppList && moreUseAppList.length > 0 && <div className="more-use-app-wrap">
        <div className="more-use-app-title">{t('大家都在用')}</div>
        <div>
          {
            moreUseAppList.map((item, index) => {
              return <AppItem key={index} data={item} />
            })
          }
        </div>
      </div>
    }
    {
      searchList && searchList.length > 0 && <div className="more-use-app-wrap">
        <div className="more-use-app-title">搜索结果</div>
        {
          searchList.map((item, index) => {
            return <AppItem key={index} data={item} />
          })
        }
      </div>
    }

  </div>
}

export default withTranslation()(SearchList);